import React, { useEffect, useState } from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  adding: { isAdding: false, addingId: "" },
  checkout: { lineItems: [] },
  products: [],
  dob: {
    value: "",
    passed: false,
    fullAttempted: false,
    successfulReturn: false,
  },
}

const GlobalContextProvider = ({ children }) => {
  const [state, updateState] = useState(initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider
        value={{
          addVariantToCart: lineItemsToAdd => {
            updateState(previousState => {
              return {
                ...previousState,
                adding: { isAdding: true, addingId: lineItemsToAdd },
              }
            })
          },
          clearDOB: () => {
            sessionStorage.removeItem("dob")
            updateState(previousState => {
              return {
                ...previousState,
                dob: { value: "", passed: false },
              }
            })
          },
          updateDOB: (year, month, day) => {
            // checkout DOB
            let currYear = new Date().getFullYear()
            let currMonth = new Date().getMonth() + 1
            let currDay = new Date().getDate()

            let passed = false
            let fullyAttempted = false

            month = parseInt(month)
            day = parseInt(day)

            //under 18 math
            if (
              year &&
              month &&
              day &&
              month <= 12 &&
              day <= 31 &&
              year.toString().length === 4
            ) {
              fullyAttempted = true
              if (year + 18 < currYear) {
                passed = true
              } else if (year + 18 === currYear) {
                if (month < currMonth) {
                  passed = true
                } else if (month === currMonth) {
                  if (day <= currDay) {
                    passed = true
                  }
                }
              }
            }

            updateState(previousState => {
              return {
                ...previousState,
                dob: {
                  value: `${year}-${month}-${day}`,
                  passed,
                  fullyAttempted: true,
                },
              }
            })

            //add dob to session storage
            sessionStorage.setItem(
              "dob",
              JSON.stringify({
                year: year,
                month: month,
                day: day,
                passed: passed,
                fullyAttempted: fullyAttempted,
              })
            )
          },
        }}
      >
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
